.navBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    background-color: #171717;
    color: white;
  }

  .navBar > nav {
    margin: 30px;
  }
  