  .icon {
    display: flex;
    align-items: center;
  }

  .icon img {
    width: 13px;
    height: 13px;
  }
  
  .tooltip-text {
    width: 210px;
    background-color: #323232;
    color: #fff;
    font-size: 12px;
    text-align: left;
    border-radius: 6px;
    padding: 16px;
  }
  