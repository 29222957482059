.buttons {
    display: flex;
    gap: 10px;
    
  }
  
  .viewAllProjects, .chatAgent {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 114px;
    cursor: pointer;
  }
  
  .viewAllProjects {
    background-color: #ffffff;
    color: #171717;
  }
  
  .chatAgent {
    background-color: #171717;
    color: white;
    border: 1px solid white;
  }
  
  .viewAllProjects:hover {
    background: #0C47C7;
    color: #fff;

  }
  
  .chatAgent:hover {
    background-color: #ffffff;
    border: 1px solid #0C47C7;
    color: #0C47C7;
  }
  