@import '../../index.css';

body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #1e1e1e;
    color: #fff;
  }
  
.app {
    display: flex;
    flex-direction: column;
}
  
.header {
    background-color: black;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.navigation {
    display: flex;
    align-items: center;
}
  
.back-button {
    background-color: transparent;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
}
  
.divider {
    margin: 0 10px;
    color: #fff;
}
  
.project-name {
    font-size: 16px;
    margin-right: 10px;
}
  
.generator-name {
    font-size: 16px;
}
  
.view-projects-button {
    background-color: #fff;
    color: #000;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
}
  
.chat-label {
    gap: 10px;
    width: 262px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-family: PPObjectSans, ITCCompactaStd, sans-serif;;
    font-size: 15px;
    font-weight: 400;
    height: 40px;
    background-color: #1e1e1e;
    
}

.chat-label div {
    height: 40px;
    display: inline-flex;
    align-items: center;
    margin-left: 34px;
    font-size: 15px;
    width: 100%;
    font-family: 'PPObjectSans';
    
}
  
.add-chat-button {
    background-color: #1e1e1e;
    color: #fff;
    border: none;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 16px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-label .add-chat-button:last-child {
    background-color: white;
    color: black;
}

.tabHolder {
    background-color: #111111;
}

.large-label-holder {
    height: 67px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    background: #1E1E1E;
    box-shadow: 0px 4px 4px 0px #00000040;
    position: relative;
}

.large-label-text {
    font-family: PPObjectSans, ITCCompactaStd, sans-serif;
    font-size: 17px;
    line-height: 22.1px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px;
}

a.large-label-text{
    font-family: PPObjectSans, ITCCompactaStd, sans-serif;
    font-size: 15px;
    line-height: 22.1px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    text-decoration: none;
}
a.large-label-text span {
    margin-right: 10px;
}



.editNameIcon {
    max-width: 15px;
    max-height: 15px;
    padding-left: 5px;
    margin: auto;
}

.large-label-text:first-child {
    font-weight: 700;
}

.large-label-text:last-child {
    position: relative;
}

.teamIcon {
    width: 36px;
    height: 36px;
    font-size: 14px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    background-color: rgb(255, 118, 117);
    line-height: 0%;
}

.teamIconName {
    margin-right: 10px;
    cursor: pointer;
}

.teamIcon:last-child {
    background-color: white;
}

.folderIcon {
    max-width: 15px;
    max-height: 15px;
    color: transparent;
}

.teamTooltip {
    opacity: 0;
    position: absolute;
    top: 40px;
    right: 60%;
    padding: 10px;
    background-color: #323232;
    border-radius: 10px;
    font-size: 12px;
    text-align: center;
    width: max-content;
    transition: opacity 0.3s;
    z-index: 1;
}

.teamIconName:hover  + .teamTooltip {
    opacity: 1;
}


.qLogo {
    height: 20px;
}