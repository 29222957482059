.header {
    position: relative;
    background: url('../../../public/tall_banner.png') no-repeat center bottom;
    background-size: cover;
    color: white;
    padding: 20px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .agentHeader {
    position: relative;
    background: url('../../../public/agentBanner.png') no-repeat center bottom;
    background-size: cover;
    color: white;
    padding: 0px;
    height: 89px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .headerTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
  }

  .agentHeaderTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto 64px;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .logo img {
    height: 21px;
  }

  .agentLogo {
    font-size: 1.5rem;
    font-weight: bold;
    max-width: 146px;
    max-height: 36px;
    cursor: pointer;
  }
  
  
  .accountDropdown {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .accountDropdown  img {
    padding: 0 5px;
  }
  .dropdownMenu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: none;
    position: absolute;
    background-color: white;
    color: black;
    top: 100%;
    right: 0;
    cursor: pointer;
  }
  

  .accountDropdown:hover .dropdownMenu {
    display: block;
  }
  
  .dropdownMenu li {
    padding: 10px;

  }

  .dropdownMenu li a {
    color: #000;
    text-decoration: none;
  }

  .dropdownMenu li a:visited {
    color: #000;
    text-decoration: none;
  }
  
  
  .dropdownMenu li:hover {
    background-color: #ddd;
  }
  
  .headerContent {
    text-align: center;
    margin-bottom: 80px;
    width: 650px;
    height:140px;
    align-self: center;
  }
  .headerContent img {
    height: 43px;
  }
  
  .headerContent h1 {
    margin: 0;
    font-size: 2rem;
  }
  
  .headerActions {
    background: #171717;
    color: white;
    padding: 20px;
    text-align: left;
  }
  
  .headerActions span {
    font-size: 1.2rem;
  }
  
  .viewAllProjects {
    padding: 10px 20px;
    font-size: 1rem;
    color: #333;
    background-color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .viewAllProjects:hover {
    background-color: #f0f0f0;
  }

  .dropdown {
  margin-bottom: 20px;
  position: relative;
}

.dropdown ul {
  color: #E8E8E8;
  cursor: pointer;
  list-style: none;
  margin: 0;
  font-size: 14px;
  background-color: #323232;

  padding: 0;
  gap: 5px;
  border-radius: 8px;
  text-align: left;

  top: 45px;
  position: absolute;
  z-index: 2;
  min-width: 128px;

  flex-direction: column;
}

.dropdown ul li {
  padding: 10px;
  border-radius: 5px;
}

.dropdown ul li:hover {
    color: #fff;
  background-color: var(--calgary-blue);
}

.dropdown-button {
  width: 50%;
  padding: 10px;
  background-color: #2a2a2a;
  color: var(--white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
  