/* Importing the fonts */
@font-face {
  font-family: "PPObjectSans";
  src: url("../public/fonts/01_Object_Sans/PPObjectSans-Regular.otf")
    format("opentype");
}
@font-face {
  font-family: "PPObjectSans";
  src: url("../public/fonts/01_Object_Sans/PPObjectSans-Bold.otf")
    format("opentype");
  font-weight: bold;
}
@font-face {
  font-family: "PPObjectSans";
  src: url("../public/fonts/01_Object_Sans/PPObjectSans-Slanted.otf")
    format("opentype");
  font-style: italic;
}
@font-face {
  font-family: "PPObjectSans";
  src: url("../public/fonts/01_Object_Sans/PPObjectSans-BoldSlanted.otf")
    format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'ITCCompactaStd';
  
  src: url('../public/fonts/02_Compacta_Standard/ITC\ -\ CompactaStd.otf') format('opentype');
}

:root {
  --rorange: #f24711;
  --pinkish: #ffe7de;
  --white: #ffffff;
  --black: #000000;
  ---eggshell: #f7faf2;
  --calgary-blue: #0c47c7; 
  --sky-blue: #d6e6f8;

  --input-grey: #262626;
  --background-grey: ;
}


body {
  margin: 0;
  font-family: 'PPObjectSans', 'ITCCompactaStd', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #171717;

  --hover-color: var(--calgary-blue);

}

