.canvasItem {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #171717;
    cursor: pointer;
    padding: 60px 0;
    gap: 12px;
    border-top: 1px solid white;
    

  }

  .itemName {
    display:inline-block;
    height: 46px;
    gap: 0px;
    font-size: 35px;
    font-weight: bold;
    width: 40%;

  }

  .itemDesc {
margin-right: auto;;

  }

  .canvasItem:hover {
    background:#0c47c7;
    color: white;
    border-top: 1px solid #0c47c7;
  }
  
  .canvasContent {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 40px;
    flex-wrap: wrap;
  }
  
  .canvasArrow {
    display: flex;
    justify-content: flex-end;
  }
  