.disclaimer {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 24px;
  border-radius: 10px;
  color: #E8E8E8;
  font-size: 12px;
  text-align: center;
  width: fit-content;
  margin: auto;
  margin-bottom: 10px;
  opacity: .65;
}

.learnMore {
  color: white;
  margin-left: 5px;
  text-decoration: underline;
}

.learnMore:hover {
  color: #0056b3;
}
