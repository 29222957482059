.App {
  font-family: "PPObjectSans", "ITCCompactaStd", sans-serif;
  text-align: center;
  background: #171717;
}

::-webkit-scrollbar {
  display: block;
  width: 7px;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #f7f7f72e;
  border-radius: 20px;
  border-right: none;
  border-left: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #f7f7f72e;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 5px;
}

::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 5px;
}

[data-amplify-authenticator] {
  height: 50vh;
}
/* [data-amplify-router] {
  display: none;
} */

.federated-sign-in-button {
  justify-self: center;
  height: 103px;
  width: 160px;
  border-radius: 10px;
  transition: box-shadow .1s linear;
  cursor: pointer;
}

.federated-sign-in-button:hover {
  box-shadow: 0 4px 18px rgba(0,0, 0, .2)
}
.federated-sign-in-container {
  display: flex;
  justify-content: center;
}
