.agentList {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0px;
    padding: 0px;
  }
  
  @media (min-width: 768px) {
    .agentList {
      grid-template-columns: 1fr 1fr;
    }
  }
  