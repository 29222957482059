.canvasDescription {
    text-align: center;
    margin: 50px 0px 20px;
  }
  
  .canvasDescription h1 {
    font-size: 32px; 
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.015em;
    color: white;
  }
  
  .canvasDescription p {
  
    font-size: 16.58px;
    font-weight: 400;
    line-height: 21.56px;
    text-align: center;
    color: white;
    margin-top: -20px;

  }
  