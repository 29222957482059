.breadcrumb {
  font-family: Mark OT;
  font-size: 12px;
  font-weight: 700;
  line-height: 15.21px;
  text-align: left;
}

.breadcrumb button {
  background: none!important;
  border: none;
  padding: 0!important;
  color: white;
  background-color: #171717;
  cursor: pointer;
  text-align: center;
  display: flex;
  gap: 5px;
  align-items: center;
}

.breadcrumb a:hover {
  text-decoration: underline;
}

.teamItem {
  display: inline-flex; /* Ensure it stays inline with other breadcrumb items */
  align-items: center; /* Vertically align the items within the flex container */
  border-radius: 5px;
  cursor: pointer;
  padding-top: 0; /* Reset padding for better alignment */
  padding-bottom: 0; /* Reset padding for better alignment */
  padding-left: 10px;
  font-family: PPObjectSans, ITCCompactaStd, sans-serif;;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
}

.teamIcon {
  display: inline-flex;
  width: 22px;
  height: 22px;
  border-radius: 50%; /* Use 50% for a perfect circle */
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}
