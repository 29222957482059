.canvasList {
  width: 80%; /* Set the width to 80% */
  margin: 0 auto; /* Center the list */
  background: #171717;
  color: white;
  padding: 20px;
  text-align: left;
}

.canvasList h2 {
  margin-top: 0;
  padding-top: 120px;
  padding-bottom: 120px;
  font-family: PPObjectSans, ITCCompactaStd, sans-serif;;
  font-size: 21px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1em;
}

.canvasList ul {
  list-style: none;
  padding: 0;
}

.headerActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
