.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #282828;
  width: 600px;
  height: 400px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100%;
  
}

.modalContent h2 {
  margin-top: 0;
font-family: PPObjectSans, ITCCompactaStd, sans-serif;;
font-size: 16px;
font-weight: 700;
line-height: 20.8px;
text-align: left;

}

.promptContainer {
  flex-grow: 1;
  width: 95%;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #1e1e1e;
  color: white;
  text-align: left;
}

.promptContainer pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
}
