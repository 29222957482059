.clickable  {
    cursor: pointer;
    width: 20px;
    height: 20px;

}


.clickable:hover path {
    fill: white;
    stroke: white;
    fill-opacity: 0;
}

/* .active {
    fill: white;
   
}

.active path {
    fill: white;

}

.active:hover path {
    fill: white;
    stroke: black;
    fill-opacity: 1;
} */