.mainContent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.chatContainer {
  flex-grow: 1;
  overflow-y: auto;
  height: 450px;
  font-family: Mark OT;
  font-weight: 450;
  line-height: 20.28px;
  margin: 0 80px;
}

.chatContainer h1 {
  line-height: 30.28px;
}

.agentMessage,
.userMessage {
  padding: 10px;
  overflow: auto;
  text-align: left;
}

.agentMessage .messageBubble {
  background-color: var(--input-grey); /* Agent message background */
  color: white;
  border-radius: 10px;
  max-width: 70%;
  align-self: flex-start;
}

.userMessage .messageBubble {
  background-color: #3d3d3d;
  color: white;
  border-radius: 10px;
  padding: 10px;
  max-width: 70%;
  align-self: flex-end;
}

.agentMessage {
  align-items: flex-start;
  /* It's already aligned to the left by default, but you can explicitly define it */
  justify-content: flex-start;
  flex-direction: column;
}

.userMessage {
  align-items: flex-start;
  justify-content: flex-end;
}

.messageBubble {
  display: flex;
  flex-direction: column;
}

.userMessage .messageBubble p {
  margin: 0px;
}

.userMessage .iconAndText {
  justify-content: end;
}

.userMessage .iconAndText .messageBubble {
  max-width: 90%;
}

.userIcon {
  margin-right: 10px;
  max-width: 20px;
  max-height: 20px;
}

.actionIcons {
  display: flex;
  gap: 10px;
  justify-content: flex-start; /* Adjust alignment if needed */
  padding-left: 38px;
}

.actionIcons img {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.messageContent {
  display: flex;
  flex-direction: column;
}

.chatInputContainer {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #333;
  position: sticky;
  bottom: 0;
  z-index: 1000;
  border-radius: 50px;
  background: var(--input-grey);
}

.chatInput {
  width: 95%;
  border: none;
  border-radius: 50px;
  background: var(--input-grey);
  color: white;
  font-size: 16px;
  margin: auto 0px;
  resize: none;
  overflow: hidden;
  font-family: "PPObjectSans";
  outline: none;
}

.sendButton {
  background: none;
  border: none;
  cursor: pointer;
  margin: auto;
  display: flex;
}

.sendButton img {
  width: 24px;
  height: 24px;
}

.disclaimer {
  position: sticky;
  bottom: 60px;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  opacity: 0.75;
}

.fixed-bottom-content {
  position: sticky;
  bottom: 0;
  background: #1e1e1e; /* Or any color that matches your design */
  width: 100%;
  padding: 10px 0; /* Optional: Adds some spacing */
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.tableHeader,
.tableCell {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.tableHeader {
  background-color: #444;
  color: white;
}

/* Code block styles */
.codeBlock {
  background-color: #2d2d2d;
  color: #f8f8f2;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.code {
  background-color: #2d2d2d;
  color: #f8f8f2;
  padding: 2px 4px;
  border-radius: 3px;
}

.iconAndText {
  display: flex;
}

.iconAndText img {
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 1px 0px auto 0px;
  padding-right: 20px;
  height: 18px;
  width: 18px;
}

.agentMessage .iconAndText .messageBubble p:first-child {
  margin-top: 0px;
}

.bottomBar {
  margin: auto;
}

.loading span {
  display: inline-block;
  margin: 0 2px;
  animation: loading 1s infinite;
}

.loading span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.copySuccess {
  opacity: 1;
}

.change {
  opacity: 0;
  transition: all 1s;
}

.stickyBottom {
  margin: 0 80px;
  margin-bottom: 10px;
}
