.canvasNavigation {
    display: flex;
    justify-content: center;
    margin: 20px 0 135px 0;
  }
  
  .canvasNavigation ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 20px;
  }
  
  .canvasNavigation li {
    cursor: pointer;
    padding: 10px 20px;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-weight: normal;

  }
  
  .canvasNavigation li:hover,
  .canvasNavigation .active {
    border-bottom: 2px solid white;
  }
  