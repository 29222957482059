.agentTile {
  background-color: #171717;
  padding: 20px;
  border-radius: 0px;
  border: #3C3B3B 1px solid;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.3s;
  color: white;
}

.agentValue {
  padding: 80px 60px 80px 60px;
  text-align: start;
  text-align-last: left;
}

.agentTile:hover {
  background-color: var(--hover-color);
  /* border: var(--hover-color) 1px solid; */
}

.agentTile:hover p{
  color: #ffffff;
}

.agentTile h2 {
  margin: 0;
  font-size: 27px;
}

.agentTile p {
  margin: 10px 0 0 0;
  color: #aaa;
  flex-grow: 1;
  text-align: start;
}

.agentTile .arrow {
  align-self: flex-end;
  font-size: 35px;
  color: white;
  margin-top: -40px;
}

/* Hide left border for tiles in odd positions (left side) */
.agentTile:nth-child(odd) {
  border-left: none;
}

/* Hide right border for tiles in even positions (right side) */
.agentTile:nth-child(even) {
  border-right: none;
}
