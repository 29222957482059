/* body {
    background-color: #121212;
    color: #ffffff;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
} */
:root {
  --font-size: 20px;
  --translate: var(--font-size) / 10 * 6;
}

.container {
  background-color: #1e1e1e;
  width: 37.5vw; /* Width should take up 3/8 of a collum in a grid but we are not using grid*/
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dropdown {
  margin-bottom: 20px;
  position: relative;
}

.dropdown ul {
  color: #e8e8e8;
  cursor: pointer;
  list-style: none;
  margin: 0;
  font-size: 14px;
  background-color: #323232;

  padding: 0;
  gap: 5px;
  border-radius: 8px;
  text-align: left;

  top: 45px;
  position: absolute;
  z-index: 2;
  min-width: 128px;

  flex-direction: column;
}

.dropdown ul li {
  padding: 10px;
  border-radius: 5px;
}

.dropdown ul li:hover {
  color: #fff;
  background-color: var(--calgary-blue);
}

.dropdown-button {
  width: 50%;
  padding: 10px;
  background-color: #2a2a2a;
  color: var(--white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
.form {
  margin: 24px 0;
}
.form-group {
  margin: 0 24px 15px 24px;
  width: 90%;
  line-height: 17px;
}

label {
  display: block;
  /* width: 50%; */
  margin-bottom: 5px;
  margin-right: 5px;
  margin: 5px;
  font-weight: bold;
  text-align: left;
  font-size: 14px;
  font-family: "PPObjectSans";
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #222;
  color: white;
  outline: none;
  resize: none;
}

.form-group textarea:focus {
  border: 1.5px solid #c4c4c4;
}

.error,
.form-group textarea:focus.error {
  border: 1px solid #c42323;
}

.errorText {
  color: #c42323;
}

.generate-button {
  width: 320px;
  padding: 10px 20px;
  background: var(--calgary-blue);
  color: #ffffff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 17px;
  align-items: center;
  margin: 35px;
}

.generate-button:hover {
  background-color: var(--calgary-blue);
}

.generate-button:disabled {
  background: #3d3d3d; /* Grayed out background */
  color: #777777; /* Lighter text color */
  cursor: not-allowed; /* Change cursor to indicate the button is disabled */
}

.tooltip-container {
  display: flex;
}

.view-prompt {
  display: flex;
  width: max-content;
  border-bottom: solid 0.5px white;
}

.view-prompt:hover {
  cursor: pointer;
}

.tooltip-icon-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 231px;

  background-color: #323232;
  color: #fff;
  font-size: 12px;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width value to align it */
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltip-text p {
  margin: 0;
}
.tooltip-icon-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.duplicateContainer {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  background-color: #292929;
  color: var(--white);
  border-radius: 5px;
  font-size: 13px;
  padding: 15px;
  text-align: left;
}

.duplicateContainer p {
  text-align: left;
  margin-top: 0;
}

.duplicateLink {
  color: var(--white);
  text-decoration: none;
  text-align: left;
  font-weight: bold;
  border-bottom: solid 0.5px var(--white);
}

.scrollable-content {
  max-height: 90%; /* Adjust 100px based on the height of your fixed content */
  overflow-y: auto;
  overflow-x: hidden;
  /* max-height: calc(100vh - 200px);  Adjust 100px based on the height of your fixed content */
}

.fixed-bottom-content {
  background: #1e1e1e;
  width: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  border-top: solid 1px #444;
  position: sticky;
  bottom: 0;
}

.subInput {
  display: flex;
  justify-content: space-between;
  color: #a29d9d;
  border-radius: 5px;
  font-size: 10px;
  line-height: 12px;
  padding: 0;
  border: 1px solid #1e1e1e;
}

.subInput p {
  padding: 0;
  margin: 0;
}

.hidden {
  visibility: hidden;
}
